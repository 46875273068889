import React from 'react';
import { Helmet } from "react-helmet"
import Social from "../components/social.js"
import Layout from "../components/LayoutAlt.js"
import HERO from "../images/Hero.jpg"
import community from "../images/community.jpg"
import Mdev from "../images/Mdev.jpg"
import mobile from "../images/mobile.jpg"




const Projects = () =>{

    return(
        <Layout>

      <Helmet>  
        <meta charSet="utf-8" />
        <meta name="description" content="Do you want to work for Jesus? Aid Him in saving Souls. Be a disciple of Christ." />
        <title>ReaCH | Projects</title>
        <link rel="canonical" href="https://reachun.org/projects" />
      </Helmet>

          <section className="sm:w-3/4 sm:ml-1/8 xs:mt-10 sm:mt-20 mb-20">
           <div className="font-montserrat font-light text-blue-dark  text-center ">
           <h1 className="xs:text-4xl sm:text-6xl md:text-7xl">
           ReaCh<span className="font-bold">Mobile</span>
           </h1>
           <p className="bg-blue-dark font-opensans xs:text-base sm:text-2xl text-white py-2 tracking-wider">Preach the Word, let the Gospel Go!</p>
           </div>
           <div className="font-playfairdisplay font-bold text-gray-700 xs:px-4 sm:px-0">
                <p className="xs:mt-10 md:mt-20  xs:text-5xl ">Wer're Sorry there are no Projects at the moment.  </p>
                <p className="mt-5 text-4xl ">Stay Tuned for upcoming Projects.</p>
           </div>
            
             
             <div className="xs:p-2 xs:grid-cols-1 xs:gap-2 sm:grid-cols-2 lg:grid-cols-4 mt-20 xs:mt-5 lg:mt-10 relative hidden">
                <div className="bg-blue-light 2xl:row-span-2 2xl:col-span-2 xs:col-span-1 sm:col-span-2 lg:row-span-1 lg:col-span-4 xs:h-84 2xl:h-auto">
                <div className="absolute ml-3 lg:ml-10 ">
                        <h1 className="font-montserrat font-bold xs:text-3xl sm:text-4xl text-5xl 2xl:mt-24 xs:mt-5 sm:mt-5 md:mt-10 text-white">This is a heading</h1>
                        <h3 className="font-montserrat font-bold xs:text-xl sm:text-2xl text-3xl mt-2 text-white">This is a Sub-heading</h3>
                        <p className="font-opensans text-white mt-2 xs:w-4/5 md:w-1/2 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                        <p className="font-opensans font-bold text-white 2xl:mt-10 xs:mt-5 sm:mt-3 md:mt-5 lg:mt-5">Project Date: May 21st 2022</p>
                        <button className="font-montserrat font-bold lg:text-3xl md:text-xl text-white border-4 border-white xs:mt-5 sm:mt-5 md:mt-5 lg:mt-7 2xl:mt-20 px-4 py-2">Join US</button>
                    </div>
                    <img src={HERO} alt="Hero" className="w-full h-full object-cover" />
                </div>

                <div className="bg-blue-light xs:h-72 lg:col-span-2 lg:h-72 2xl:col-span-1">
                <div className="absolute ml-3 lg:ml-10">
                        <h1 className="font-montserrat font-bold text-2xl mt-8 text-white">This is a heading</h1>
                        <h3 className="font-montserrat font-bold text-lg mt-2 text-white">This is a Sub-heading</h3>
                        <p className="font-opensans text-sm text-white mt-2 xs:w-3/4 sm:w-1/2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt. </p>
                        <p className="font-opensans font-bold text-xs text-white mt-5">Project Date: May 21st 2022</p>
                        <button className="font-montserrat font-bold text-lg text-white border-4 border-white mt-5 px-4 py-1">Join US</button>
                    </div>
                <img src={community} alt="Hero" className="w-full h-full object-cover" />
                </div>

                <div className="bg-blue-light lg:col-span-2 2xl:col-span-1 h-72">
                <div className="absolute ml-3 lg:ml-10">
                        <h1 className="font-montserrat font-bold text-2xl mt-8 text-white">This is a heading</h1>
                        <h3 className="font-montserrat font-bold text-lg mt-2 text-white">This is a Sub-heading</h3>
                        <p className="font-opensans text-sm text-white mt-2 sm:w-full md:w-3/4  xl:w-full">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt. </p>
                        <p className="font-opensans font-bold text-xs text-white mt-5">Project Date: May 21st 2022</p>
                        <button className="font-montserrat font-bold text-lg text-white border-4 border-white mt-5 px-4 py-1">Join US</button>
                    </div>
                <img src={Mdev} alt="Hero" className="w-full h-full object-cover" />
                </div>

                <div className="bg-blue-light  xs:h-84 sm:col-span-2 md:h-72 md:col-span-2 lg:col-span-4 2xl:col-span-2">
                <div className="absolute ml-3 lg:ml-10">
                        <h1 className="font-montserrat font-bold xs:text-3xl sm:text-4xl mt-7 text-white">This is a heading</h1>
                        <h3 className="font-montserrat font-bold text-2xl mt-2 text-white">This is a Sub-heading</h3>
                        <p className="font-opensans text-white mt-2 w-11/12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et. </p>
                        <p className="font-opensans font-bold text-white mt-4">Project Date: May 21st 2022</p>
                        <button className="font-montserrat font-bold text-lg text-white border-4 border-white mt-4 px-4 py-1">Join US</button>
                    </div>
                <img src={mobile} alt="Hero" className="w-full h-full object-cover" />
                </div>
             </div>
          </section>
          <Social />
        </Layout>
        
    )
}

export default Projects